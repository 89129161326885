<template>
  <section class="login">
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth">
          <div class="row w-100 flex-grow">
            <div class="col-xl-4 col-lg-6 mx-auto">
              <div class="auth-form-light text-left p-5">
                <div class="brand-logo">
                  <img src="../../../assets/images/logo.svg" />
                </div>
                <h4>Reset Password</h4>
                <h6 class="font-weight-light">Check your email for your otp</h6>
                <ValidationObserver v-slot="{ invalid }">
                  <form
                    @submit.prevent="onSubmit"
                    class="pt-3"
                    autocomplete="off"
                  >
                    <ValidationProvider name="email" rules="required|email">
                      <div slot-scope="{ errors }" class="form-group">
                        <input
                          autocomplete="chrome-off"
                          type="email"
                          v-model="email"
                          disabled
                          class="form-control form-control-lg"
                          id="email"
                          placeholder="Email"
                        />
                        <p>{{ errors[0] }}</p>
                        <p>{{ errors[1] }}</p>
                      </div>
                    </ValidationProvider>
                    <ValidationProvider name="token" rules="required|min:5">
                      <div slot-scope="{ errors }" class="form-group">
                        <input
                          autocomplete="chrome-off"
                          type="text"
                          v-model="token"
                          class="form-control form-control-lg"
                          id="token"
                          placeholder="token"
                        />
                        <p>{{ errors[0] }}</p>
                        <p>{{ errors[1] }}</p>
                      </div>
                    </ValidationProvider>
                    <ValidationProvider name="password" rules="min:6">
                      <div slot-scope="{ errors }" class="form-group">
                        <input
                          autocomplete="chrome-off"
                          type="password"
                          v-model="password"
                          class="form-control form-control-lg"
                          id="email"
                          placeholder="Enter your new password"
                        />
                        <p>{{ errors[0] }}</p>
                      </div>
                    </ValidationProvider>
                    <div class="mt-3">
                      <b-button
                        type="submit"
                        :disabled="invalid"
                        variant="primary"
                        class="
                          btn btn-block btn-gradient-primary btn-lg
                          font-weight-medium
                          auth-form-btn
                        "
                        >{{ sign_in }}</b-button
                      >
                    </div>
                    <div
                      class="
                        my-2
                        mt-3
                        d-flex
                        justify-content-center
                        align-items-center
                      "
                    >
                      <p class="text-muted mb-0">
                        If you did not received any email click
                        <i
                          v-if="!sendToken"
                          @click="resendToken()"
                          class="small-text"
                        >
                          here to resend token</i
                        >
                        <i v-else>sending...</i>
                      </p>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
        <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>
</template>

<script>
import { ValidationObserver } from "vee-validate";

export default {
  name: "reset-password",
  data() {
    return {
      email: "",
      password: "",
      token: "",
      sendToken: false,
      sign_in: "SUBMIT",
    };
  },
  components: {
    ValidationObserver,
  },
  methods: {
    resendToken() {
      if (this.sign_in === "PROCESSING") return;
      this.sendToken = true;
      this.$store
        .dispatch("auth/passwordResetToken", {
          email: this.email,
        })
        .then((data) => {
          this.sendToken = false;
          if (typeof data === "string") {
            this.sign_in = "RESET PASSWORD";
            this.$snotify.warning(data, {
              timeout: 2000,
              showProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            this.sign_in = "RESET PASSWORD";
            this.$snotify.success(
              "Otp sent succefully to your mail. \n Kindly check your mail",
              {
                timeout: 2000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
              }
            );
          }
        });
    },
    checkLogin() {
      if (this.$store.state.auth.isUserLoggedIn()) {
        this.$snotify.warning("User already logged in", {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
        });
        return false;
      }
      return true;
    },
    onSubmit() {
      this.sign_in = "PROCESSING...";
      if (!this.checkLogin()) return;
      const payload = {
        email: this.email,
        password: this.password,
        token: this.token,
      };
      this.$store
        .dispatch("auth/resetPassword", payload)
        .then(() => {
          this.$snotify.success("Password Changed successfully!", {
            timeout: 1100,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
          });
          localStorage.removeItem("resetEmail");
          setTimeout(() => {
            location.replace("login");
          }, 1100);
        })
        .catch((error) => {
          this.sign_in = "SUBMIT";
          this.$snotify.warning(error, {
            timeout: 2000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
          });
        });
    },
  },
  created() {
    this.password = "";
    this.token = "";
    const email = localStorage.getItem("resetEmail");
    this.email = email;
  },
};
</script>
<style scoped lang="scss">
.simple-snotify {
  display: flex;
  justify-content: center;
}
.small-text {
  font-size: 14px;
  text-decoration: underline;
  color: cadetblue;
  cursor: pointer;
}
</style>
